import React from 'react';
import Aux from '../../../hoc/Auxillian';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import NavigationItem from './NavigationItem/NavigationItem';
import Logo from '../../../assets/svg/logo_horizontal.js';
import * as actions from '../../../store/actions/';
import * as colors from '../../../hoc/shared/colors';

function NavigationItems(props) {

    const checkDirNumber = pathname => {
        if(pathname === "/") {
            return 1;
        } else if(pathname === "/about") {
            return 2;
        } else if(pathname === "/skills") {
            return 3;
        } else if(pathname === "/portfolio") {
            return 4;
        } else if(pathname === "/consulting") {
            return 5;
        } else if(pathname === "/blog") {
            return 6;
        } else if(pathname === "/contact") {
            return 7;
        } else {
            return 0;
        }
    }

    let timer1;
    let timer2;

    const clicked = (e, dir) => {
        window.scrollTo(0,0);
        e.preventDefault();
        props.mountingPageDir(" ");
        
        const pathname = history.location.pathname;
        if(pathname !== dir) {

            if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Dec")
                props.mountingPageDir("Dec");
            } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Inc")
                props.mountingPageDir("Inc");
            }
            props.setTransitionPageClass("transitionPage-active");
            props.changeNavFontColor(colors.mainColor1);

            clearTimeout(timer1, timer2);

            timer1 = setTimeout(() => {
                history.push(dir)
                if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Dec")
                } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Inc")
                }
            timer2 = setTimeout(()=>{
                props.changeNavFontColor(colors.mainColor3);
            },400)
            },500)
        }
    }
    
    const home = (
        <a href="/"  onClick={e => clicked(e, '/')} >
            <Logo
                classMain="logo_horizontal"
                class1="logo_horizontal_st0"
                class2="logo_horizontal_st1"
                color1={colors.mainColor5}
                color2={props.navFontColor}
                alt="Tomasch Schwiertz"/>
        </a>
    )

    const P = props => {
        return (
            <div>
                <p>{props.children}</p>
            </div>
        )
    }

    const history = useHistory();

    return(
        <Aux>
        {home}
        <NavigationItem link={'/'}
            clicked={clicked}
        >
            <P>HOME</P>
        </NavigationItem>
        <NavigationItem link={'/about'}
            clicked={clicked}
        >
            <P>ABOUT TOM</P>
        </NavigationItem>
        <NavigationItem link={'/skills'}
            clicked={clicked}
        >
            <P>SKILLS</P>
        </NavigationItem>
        <NavigationItem link={'/portfolio'}
            clicked={clicked}
        >
            <P>PORTFOLIO</P>
        </NavigationItem>
        <NavigationItem link={'/consulting'}
            clicked={clicked}
        >
            <P>CONSULTING</P>
        </NavigationItem>
        {/* <NavigationItem link={'/blog'}
            clicked={clicked}
        >
            <P>BLOG</P>
        </NavigationItem> */}
        <NavigationItem link={'/contact'}
            clicked={clicked}
        >
            <P>CONTACT</P>
        </NavigationItem>
        </Aux>
    )
}

const mapStateToProps = state => {
    return {
        navFontColor: state.ui.navFontColor
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeMountedClasses: classes => dispatch(actions.changeMountedClasses(classes)),
        setTransitionPageClass: classes => dispatch(actions.setTransitionPageClass(classes)),
        isRouteMounted: bool => dispatch(actions.isRouteMounted(bool)),
        changeNavFontColor: color => dispatch(actions.changeNavFontColor(color)),
        mountingPageDir: dir => dispatch(actions.pageHaseMountedDir(dir))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationItems);