import * as actionTypes from '../actions/actionTypes';
import { updatedObject } from '../../hoc/shared/utility';
import * as colors from '../../hoc/shared/colors';

const initialState = {
    navFontColor: colors.mainColor3,
    firstLoad: true,
    firstLoadClasses: 'firstLoadPage',
    mountedClass: 'App',
    transitionPageClass: ' ',
    routeHaseMounted: true,
    pageHaseMounted: ""
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.IS_FIRST_LOAD:
            return updatedObject(state, {firstLoad: action.bool});
        case actionTypes.CHANGE_FIRST_LOAD_CLASSES:
            return updatedObject(state, {firstLoadClasses: action.classes});
        case actionTypes.CHANGE_MOUNTED_CLASSES:
            return updatedObject(state, {mountedClass: action.classes});
        case actionTypes.SET_TRANSITION_PAGE_CLASS:
            return updatedObject(state, {transitionPageClass: action.classes});
        case actionTypes.IS_ROUTE_MOUNTED:
            return updatedObject(state, {routeHaseMounted: action.bool});
        case actionTypes.CHANGE_NAV_FONT_COLOR:
            return updatedObject(state, {navFontColor: action.color});
        case actionTypes.PAGE_HASE_MOUNTED_DIR:
            return updatedObject(state, {pageHaseMounted: action.dir});
        default:
            return state;
    }
}

export default reducer;