import React from 'react';
import { connect } from 'react-redux';
import './FirstLoadPage.scss';

function FirstLoadPage(props) {
    return (
        <div className={props.firstLoadClasses}></div>
    )
}

const mapStateToProps = state => {
    return {
        firstLoadClasses: state.ui.firstLoadClasses
    }
}
export default connect(mapStateToProps)(FirstLoadPage);