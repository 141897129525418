import React from 'react';

export default function LogoHorizontal(props) {

const st0 = {
	className: props.class1,
	style: {fill: props.color1}
}
const st1 = {
	className: props.class2,
	style: {fill: props.color2}
}
	return(
<svg className={props.classMain} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1000 250" style={{"enableBackground":"new 0 0 1000 250"}} xmlSpace="preserve">

<g>
	<g>
		<g>
			<g>
				<path {...st0} d="M305.41,181.78c2.65,3.77,4.8,7.12,6.46,10.04h0.17c-0.22-4.8-0.33-8.3-0.33-10.49v-16.72h6.76v36.93h-7.09
					l-12.03-17c-2.11-2.94-4.31-6.35-6.57-10.24h-0.17c0.22,4.52,0.33,8.03,0.33,10.51v16.72h-6.76v-36.93h7.09L305.41,181.78z"/>
				<path {...st0} d="M345.7,195.88h18.16v5.66h-24.92v-36.93h23.93v5.66H345.7v9.55h14.74v5.66H345.7V195.88z"/>
				<path {...st0} d="M393.92,201.54h-6.76v-31.27h-11.92v-5.66h30.57v5.66h-11.89V201.54z"/>
				<path {...st0} d="M440.89,174.33c-0.72,3.44-1.55,6.99-2.48,10.65l-4.14,16.56h-6.73l-11.73-36.93h7.21l4.44,14.71
					c1.35,4.62,2.43,8.79,3.26,12.53h0.16c0.81-4.01,1.76-8.12,2.82-12.34l3.81-14.9h6.95l3.81,14.9c1.06,4.17,2.01,8.29,2.81,12.34
					h0.17c1.01-4.42,2.09-8.59,3.25-12.53l4.44-14.71h7.2l-11.73,36.93h-6.73l-4.14-16.56c-0.94-3.74-1.77-7.29-2.49-10.65H440.89z"
					/>
				<path {...st0} d="M514.87,183.08c0,2.82-0.4,5.39-1.2,7.74c-0.79,2.35-1.96,4.36-3.51,6.05c-1.53,1.69-3.42,3.01-5.65,3.95
					c-2.23,0.94-4.78,1.41-7.63,1.41c-2.85,0-5.4-0.47-7.64-1.41c-2.24-0.94-4.12-2.26-5.65-3.95c-1.54-1.69-2.7-3.71-3.51-6.05
					c-0.8-2.35-1.2-4.92-1.2-7.74c0-2.82,0.4-5.4,1.2-7.74c0.8-2.35,1.97-4.37,3.51-6.06c1.53-1.69,3.42-3.01,5.65-3.94
					c2.23-0.94,4.78-1.41,7.64-1.41c2.84,0,5.39,0.47,7.63,1.41c2.24,0.94,4.12,2.25,5.65,3.94c1.54,1.69,2.71,3.71,3.51,6.06
					C514.47,177.68,514.87,180.27,514.87,183.08z M507.88,183.08c0-2.25-0.27-4.21-0.82-5.89c-0.55-1.69-1.31-3.09-2.29-4.21
					c-0.97-1.13-2.14-1.96-3.47-2.51c-1.35-0.55-2.82-0.83-4.42-0.83c-1.61,0-3.08,0.28-4.42,0.83c-1.35,0.55-2.51,1.39-3.48,2.51
					c-0.98,1.12-1.74,2.52-2.29,4.21c-0.55,1.68-0.83,3.65-0.83,5.89c0,2.24,0.27,4.2,0.83,5.89c0.55,1.68,1.31,3.08,2.29,4.2
					c0.97,1.11,2.13,1.95,3.48,2.51c1.34,0.56,2.81,0.84,4.42,0.84c1.59,0,3.07-0.28,4.42-0.84c1.34-0.56,2.5-1.4,3.47-2.51
					c0.98-1.11,1.74-2.51,2.29-4.2C507.61,187.28,507.88,185.32,507.88,183.08z"/>
				<path {...st0} d="M561.02,175.71c0,2.58-0.72,4.76-2.14,6.56c-1.43,1.79-3.63,3.12-6.61,3.99v0.11l10.98,15.18h-8.12
					l-10.05-14.3h-4.63v14.3h-6.76v-36.93h11.23c1.85,0,3.59,0.11,5.18,0.32c1.61,0.21,2.97,0.53,4.11,0.95
					c2.17,0.83,3.85,2.06,5.03,3.68C560.43,171.2,561.02,173.24,561.02,175.71z M543.8,181.59c1.54,0,2.85-0.07,3.93-0.21
					c1.07-0.14,1.99-0.35,2.73-0.62c1.35-0.52,2.3-1.21,2.82-2.11c0.53-0.89,0.79-1.92,0.79-3.07c0-0.99-0.21-1.87-0.62-2.64
					c-0.41-0.76-1.12-1.37-2.12-1.81c-0.64-0.29-1.45-0.51-2.43-0.65c-0.98-0.14-2.18-0.21-3.61-0.21h-4.83v11.32H543.8z"/>
				<path {...st0} d="M580.83,201.54v-36.93h6.76v16.8h0.12l13.93-16.8h8.39l-15.39,18.3l16.66,18.63h-8.66l-14.93-16.67h-0.12
					v16.67H580.83z"/>
				<path {...st0} d="M646.6,195.88h18.16v5.66h-24.92v-36.93h23.92v5.66H646.6v9.55h14.73v5.66H646.6V195.88z"/>
				<path {...st0} d="M702.82,181.78c2.65,3.77,4.81,7.12,6.45,10.04h0.17c-0.23-4.8-0.34-8.3-0.34-10.49v-16.72h6.76v36.93
					h-7.09l-12.03-17c-2.11-2.94-4.31-6.35-6.57-10.24h-0.16c0.22,4.52,0.33,8.03,0.33,10.51v16.72h-6.76v-36.93h7.1L702.82,181.78z
					"/>
				<path {...st0} d="M757.11,187.44h-8.29v-5.65h15.05v18.62c-0.55,0.21-1.26,0.42-2.12,0.63c-0.86,0.22-1.81,0.42-2.86,0.6
					c-1.06,0.17-2.15,0.32-3.28,0.43c-1.12,0.11-2.23,0.16-3.34,0.16c-3.31,0-6.21-0.45-8.68-1.34c-2.47-0.89-4.53-2.14-6.17-3.76
					c-1.65-1.62-2.88-3.55-3.7-5.78c-0.82-2.24-1.22-4.7-1.22-7.41c0-1.91,0.22-3.74,0.64-5.47c0.44-1.74,1.07-3.35,1.91-4.83
					c0.84-1.48,1.85-2.82,3.05-4.02c1.19-1.19,2.56-2.22,4.1-3.07c1.53-0.84,3.21-1.49,5.05-1.94c1.83-0.45,3.8-0.68,5.89-0.68
					c1.86,0,3.62,0.14,5.31,0.4c1.67,0.27,3.04,0.59,4.11,0.98v5.75c-1.44-0.46-2.88-0.82-4.35-1.05c-1.46-0.24-2.94-0.36-4.43-0.36
					c-2.01,0-3.88,0.3-5.63,0.9c-1.75,0.6-3.27,1.48-4.55,2.65c-1.27,1.16-2.28,2.61-3.02,4.32c-0.74,1.71-1.11,3.69-1.11,5.93
					c0.02,4.36,1.16,7.63,3.43,9.81c2.25,2.18,5.47,3.27,9.62,3.27c0.78,0,1.57-0.04,2.41-0.13c0.83-0.08,1.55-0.19,2.18-0.32
					V187.44z"/>
				<path {...st0} d="M783.79,201.54v-36.93h6.76v36.93H783.79z"/>
				<path {...st0} d="M830.27,181.78c2.65,3.77,4.8,7.12,6.46,10.04h0.16c-0.22-4.8-0.33-8.3-0.33-10.49v-16.72h6.76v36.93h-7.1
					l-12.03-17c-2.12-2.94-4.31-6.35-6.56-10.24h-0.17c0.23,4.52,0.34,8.03,0.34,10.51v16.72h-6.76v-36.93h7.09L830.27,181.78z"/>
				<path {...st0} d="M870.56,195.88h18.16v5.66h-24.93v-36.93h23.94v5.66h-17.17v9.55h14.74v5.66h-14.74V195.88z"/>
				<path {...st0} d="M914.3,195.88h18.16v5.66h-24.92v-36.93h23.92v5.66H914.3v9.55h14.73v5.66H914.3V195.88z"/>
				<path {...st0} d="M978.59,175.71c0,2.58-0.71,4.76-2.14,6.56c-1.42,1.79-3.62,3.12-6.61,3.99v0.11l10.98,15.18h-8.1
					l-10.05-14.3h-4.65v14.3h-6.76v-36.93h11.23c1.87,0,3.59,0.11,5.2,0.32c1.6,0.21,2.97,0.53,4.11,0.95
					c2.17,0.83,3.84,2.06,5.02,3.68C978.01,171.2,978.59,173.24,978.59,175.71z M961.38,181.59c1.55,0,2.85-0.07,3.94-0.21
					c1.08-0.14,1.98-0.35,2.72-0.62c1.37-0.52,2.31-1.21,2.83-2.11c0.52-0.89,0.78-1.92,0.78-3.07c0-0.99-0.21-1.87-0.62-2.64
					c-0.42-0.76-1.12-1.37-2.11-1.81c-0.65-0.29-1.45-0.51-2.43-0.65c-0.98-0.14-2.18-0.21-3.62-0.21h-4.84v11.32H961.38z"/>
			</g>
			<g>
				<path {...st1} d="M298.25,146.44V72.42h-9.98V61.18h32.5v11.23h-9.59v74.02H298.25z"/>
				<path {...st1} d="M360.11,141.26c-2.97,4.1-7.73,6.15-14.27,6.15c-6.54,0-11.33-2.06-14.39-6.18
					c-3.05-4.12-4.58-10.32-4.58-18.62v-39c0-15.63,6.38-23.45,19.13-23.45c6.59,0,11.33,1.99,14.22,5.96
					c2.89,3.97,4.33,9.8,4.33,17.49v39C364.56,130.94,363.08,137.15,360.11,141.26z M341.09,133.21c0.76,1.97,2.32,2.96,4.68,2.96
					c2.35,0,3.91-1.01,4.65-3.03c0.74-2.02,1.11-5.48,1.11-10.4V83.02c0-4.04-0.38-6.98-1.14-8.84c-0.76-1.86-2.24-2.78-4.45-2.78
					c-2.21,0-3.77,0.93-4.65,2.78c-0.89,1.86-1.34,4.79-1.34,8.79v39.76C339.95,127.76,340.33,131.24,341.09,133.21z"/>
				<path {...st1} d="M376.33,146.44V61.18h16.13l12.11,62.29l11.39-62.29h16.23v85.25h-11.58v-31.77l0.48-29.16l-11.48,60.55
					h-10.32L387.33,85.5l0.48,29.16v31.77H376.33z"/>
				<path {...st1} d="M441.15,146.44l12.16-85.25h17.14l12.93,85.25h-12.11l-2.04-15.21h-13.76l-1.83,15.21H441.15z
					 M467.88,120.81l-5.71-42.83h-0.19l-5.23,42.83H467.88z"/>
				<path {...st1} d="M490.6,122.46v-7.61h12.3v8.58c0,4.74,0.41,8.08,1.21,10c0.81,1.92,2.32,2.88,4.56,2.88
					c2.23,0,3.7-0.79,4.43-2.38c0.73-1.58,1.09-4.17,1.09-7.75c0-3.58-0.65-6.71-1.94-9.37c-1.29-2.66-3.34-5.45-6.15-8.36
					l-6.29-6.49c-2.94-3.07-5.23-6.25-6.86-9.54c-1.63-3.3-2.45-7.1-2.45-11.43c0-7.04,1.35-12.27,4.07-15.7
					c2.71-3.42,7.38-5.13,14.01-5.13c6.62,0,11.1,2,13.44,5.99c2.33,3.99,3.5,9.81,3.5,17.46v5.28h-11.95v-6.1
					c0-4.13-0.36-7.1-1.07-8.88c-0.71-1.8-2.07-2.69-4.09-2.69c-2.02,0-3.47,0.68-4.36,2.03c-0.89,1.36-1.34,3.39-1.34,6.1
					c0,2.71,0.46,4.88,1.36,6.49c0.91,1.62,2.54,3.68,4.89,6.21l7.03,7.61c3.52,3.81,6.17,7.67,7.96,11.58
					c1.79,3.91,2.69,8.17,2.69,12.79c0,7.97-1.34,13.87-4.03,17.68c-2.68,3.81-7.36,5.72-14.07,5.72c-6.7,0-11.36-2.13-14-6.37
					C491.92,136.79,490.6,130.6,490.6,122.46z"/>
				<path {...st1} d="M534.49,146.44v-11.53l17.69-62.54h-16.08V61.18h29.69V71.7l-17.68,63.55h17.3v11.19H534.49z"/>
				<path {...st1} d="M591.45,122.46v-7.61h12.3v8.58c0,4.74,0.41,8.08,1.22,10c0.8,1.92,2.32,2.88,4.56,2.88
					c2.23,0,3.7-0.79,4.43-2.38c0.73-1.58,1.09-4.17,1.09-7.75c0-3.58-0.65-6.71-1.94-9.37c-1.29-2.66-3.34-5.45-6.15-8.36
					l-6.3-6.49c-2.94-3.07-5.23-6.25-6.85-9.54c-1.63-3.3-2.45-7.1-2.45-11.43c0-7.04,1.36-12.27,4.07-15.7
					c2.71-3.42,7.38-5.13,14.01-5.13c6.62,0,11.1,2,13.44,5.99c2.33,3.99,3.5,9.81,3.5,17.46v5.28h-11.95v-6.1
					c0-4.13-0.36-7.1-1.07-8.88c-0.71-1.8-2.07-2.69-4.09-2.69c-2.02,0-3.47,0.68-4.36,2.03c-0.89,1.36-1.33,3.39-1.33,6.1
					c0,2.71,0.45,4.88,1.36,6.49c0.91,1.62,2.54,3.68,4.89,6.21l7.03,7.61c3.52,3.81,6.17,7.67,7.96,11.58
					c1.79,3.91,2.69,8.17,2.69,12.79c0,7.97-1.35,13.87-4.03,17.68c-2.68,3.81-7.36,5.72-14.07,5.72c-6.7,0-11.36-2.13-14-6.37
					C592.78,136.79,591.45,130.6,591.45,122.46z"/>
				<path {...st1} d="M668.57,141.47c-2.94,3.95-7.55,5.94-13.86,5.94c-6.29,0-11-2.11-14.11-6.33
					c-3.13-4.22-4.68-10.28-4.68-18.18V85.74c0-12.69,3.32-20.62,9.98-23.78c2.45-1.16,5.42-1.74,8.91-1.74
					c11.98,0,17.97,7.55,17.97,22.67V94.6h-12.54v-10.9c0-4.4-0.37-7.53-1.12-9.42c-0.74-1.89-2.19-2.84-4.35-2.84
					c-2.17,0-3.67,1.01-4.51,3.01c-0.84,2.01-1.26,5.07-1.26,9.21v39.81c0,4.59,0.4,7.86,1.21,9.83c0.8,1.97,2.28,2.96,4.41,2.96
					c2.14,0,3.6-1.01,4.41-3.01c0.82-2,1.22-5.31,1.22-9.93v-12.21h12.74v12.06C672.98,131.42,671.51,137.52,668.57,141.47z"/>
				<path {...st1} d="M683.54,146.44V61.18h12.98v34.49h11.63V61.18h12.98v85.25h-12.98v-39.62h-11.63v39.62H683.54z"/>
				<path {...st1} d="M740.85,146.44l-10.42-85.25h11.62l6.25,60.74l7.94-60.74h10.47l7.98,60.74l5.52-60.74h11.68l-9.79,85.25
					h-12.93l-7.55-56.67l-7.84,56.67H740.85z"/>
				<path {...st1} d="M801.64,146.44V61.18h12.99v85.25H801.64z"/>
				<path {...st1} d="M827.89,146.44V61.18h28.58v11.19h-15.59v24.8h14.82v10.95h-14.82v27.13h16.18v11.19H827.89z"/>
				<path {...st1} d="M884.57,106.37l-4.17-0.19v40.25h-12.99V61.18h21.46c4.46,0,7.78,0.89,9.98,2.69
					c2.19,1.8,3.64,4.3,4.33,7.51c0.69,3.22,1.04,7.42,1.04,12.6c0,5.18-0.54,9.13-1.65,11.84c-1.1,2.71-3.13,4.53-6.1,5.47
					c2.94,0.52,4.99,2.5,6.15,5.96c1.16,3.45,1.74,10.64,1.74,21.58c0,10.93,0.15,16.8,0.48,17.61h-12.98
					c-0.33-0.65-0.49-10.83-0.49-30.57c0-3.77-0.46-6.28-1.36-7.53C889.14,107.09,887.31,106.44,884.57,106.37z M880.45,96.11h5.19
					c1.65,0,2.86-0.37,3.66-1.12c0.79-0.74,1.33-2.02,1.65-3.83c0.31-1.81,0.46-4.57,0.46-8.28v-0.53c0-4.62-0.39-7.64-1.16-9.06
					c-0.78-1.42-2.57-2.13-5.38-2.13h-4.41V96.11z"/>
				<path {...st1} d="M920.7,146.44V72.42h-9.97V61.18h32.5v11.23h-9.59v74.02H920.7z"/>
				<path {...st1} d="M948.75,146.44v-11.53l17.69-62.54h-16.08V61.18h29.69V71.7l-17.68,63.55h17.29v11.19H948.75z"/>
			</g>
		</g>
		<g>
			<g>
				<path {...st1} d="M226.62,181.28c0,1.11-0.53,2.14-1.42,2.82l-90.04,43.32l-0.12-0.01l-0.57,0.39
					c-1.26,0.49-2.73,0.36-3.89-0.34l-2.48-1.5h-0.59l-32.59-15.64l-0.01,0.03l-75.98-35.41v6.33c0,1.04,0.09,2.1,0.26,3.13
					c0.07,0.59,0.14,1.23,0.3,1.94c1.35,6.06,5,11.28,10.29,14.7l91.18,43.82c3.61,2.05,7.69,3.12,11.88,3.12
					c3.08,0,6.1-0.58,9.17-1.8c0.74-0.32,1.49-0.67,2.17-1.06l87.89-42.25l3.33-1.67c6.97-4.32,11.14-11.78,11.14-19.93v-71.96
					l-20.07-9.34L226.62,181.28z"/>
			</g>
			<g>
				<path {...st1} d="M38.81,68.12c0-1.12,0.52-2.15,1.43-2.83l90.05-43.31l0.84-0.45c1.22-0.44,2.64-0.29,3.73,0.39l63.94,30.71
					l47.73,22.23v-6.75c0-1.08-0.11-2.17-0.28-3.22c-0.06-0.6-0.17-1.22-0.3-1.86c-1.33-6.06-4.99-11.28-10.27-14.7l-91.15-43.8
					c-6.3-3.55-13.66-4.29-21.22-1.28c-0.69,0.3-1.37,0.63-2.01,0.98L33.4,46.5l-3.35,1.69c-6.98,4.32-11.13,11.76-11.13,19.92
					v72.38l20.05,9.34L38.81,68.12z"/>
			</g>
		</g>
		<g>
			<path {...st1} d="M142.95,185.73h-20.38V91.52H86.65V74.47h92.13v17.05h-35.84V185.73z"/>
		</g>
	</g>
</g>
</svg>
)
}

