import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/';
import * as colors from '../../../hoc/shared/colors';
import './Button.scss';

function Button(props) {
    
    const checkDirNumber = pathname => {
        if(pathname === "/") {
            return 1;
        } else if(pathname === "/about") {
            return 2;
        } else if(pathname === "/skills") {
            return 3;
        } else if(pathname === "/portfolio") {
            return 4;
        } else if(pathname === "/consulting") {
            return 5;
        } else if(pathname === "/blog") {
            return 6;
        } else if(pathname === "/contact") {
            return 7;
        } else {
            return 0;
        }
    }

    let timer1;
    let timer2;

    const clicked = (e, dir) => {
        window.scrollTo(0,0);
        e.preventDefault()
        
        const pathname = history.location.pathname;
        if(pathname !== dir) {

            if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Dec")
            } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Inc")
            }
            props.setTransitionPageClass("transitionPage-active");
            props.changeNavFontColor(colors.mainColor1);

            clearTimeout(timer1, timer2);

            timer1 = setTimeout(() => {
                history.push(dir)
                if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Dec")
                } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Inc")
                }
            timer2 = setTimeout(()=>{
                props.changeNavFontColor(colors.mainColor3);
            },400)
            },500)
        }
    }
    
    const history = useHistory();

    const style = (type) => {
        switch(type) {
            case ("type1"):
                return {
                    backgroundColor: colors.mainColor5,
                    color: colors.mainColor1
                };
            case ("type2"):
                return {
                    backgroundColor: colors.mainColor3,
                    color: colors.mainColor1
                };
            case ("type3"):
                return {
                    backgroundColor: colors.mainColor6,
                    color: colors.mainColor2
                };
            case ("type4"):
                return {
                    backgroundColor: colors.mainColor4,
                    color: colors.mainColor2
                };
            case ("type5"):
                return {
                    backgroundColor: colors.mainColor1,
                    color: colors.mainColor3
                };
            default:
                return {
                    backgroundColor: colors.mainColor5,
                    color: colors.mainColor1
                }
        }
    }
    
    const scrollToRef = (e, ref) => {
        e.preventDefault();
        return ref.current.scrollIntoView()
    }

    const openNewTab = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank', 'noopener, noreferrer')
    }

    const pathType = (e, path) => {
        switch(path) {
            case("type1"):
                return clicked(e, props.path);
            case("type2"):
                return scrollToRef(e, props.elementRef);
            case("type3"):
                return props.clicked(e);
            case("type4"):
                return openNewTab(e, props.path);
            default:
                return null;

        }
    }

    return (
        <div
            className={'button ' + props.classMain}
            style={style(props.type)}
            onClick={e => pathType(e, props.pathType)}
        >
            {props.children}
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        changeMountedClasses: classes => dispatch(actions.changeMountedClasses(classes)),
        setTransitionPageClass: classes => dispatch(actions.setTransitionPageClass(classes)),
        isRouteMounted: bool => dispatch(actions.isRouteMounted(bool)),
        changeNavFontColor: color => dispatch(actions.changeNavFontColor(color))
    }
}

export default connect(null, mapDispatchToProps)(Button);