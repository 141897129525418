const initialState = {
    data: {
        project1: {
            title: `Automated BGP Filter Update`,
            subtitle: `ISP :: Network Automation`,
            description: `Automated BGP filter update. Python script reads BGP prefixes from GitHub and updates PE configuration via NETCONF.`,
            overview: `Automated BGP filter update, as a Network Automation project for ISPs. Python script reads information from a Version Control System (Git) and updates Provider Edge router configuration via NETCONF.`,
            challenge: `Every time an IP Transit (IPT) customer gets onboarded - inbound and outbound filters are updated with the customer prefixes. A BGP session's configuration towards the customer is bespoke and is usually done manually as the customer has "various requirements". Along with accepting customer prefixes inbound - ISP need to announce these new prefixes outbound towards Transit Providers. By this - we understand - to re-configure outbound prefix list filters. This re-configuration needs to happen on all PE routers facing Transit ISP. The more Transit networks ACME Telecom peers with - the more times must the same change be implemented. This repetitiveness is targeted by the below Automation Solution.
            
            After deciding that a script will change the configuration of PE routers - it became clear that another system was needed to keep track of the changes. The choice fell on Git and its associated cloud-based hosting service - GitHub. While Git keeps track of the changes to the list of prefixes - GitHub is the repository that makes working with the list of prefixes easier.`,
            contribution: `An already working automation solution was already in place in the ISP I worked for when writing this portfolio project. The ISP was automating BGP filter updates on CISCO IOS-XR routers while hosting IPv4/IPv6 prefix-set's and as-path-set's on Kiln (commercial Version Control System). 
                       
            The company was slowly moving away from being a CISCO only house while deploying more and more Juniper MX routers in the network. Because of the existing Automated System, because of the "vendor lock-in" - the decision on where to connect new Transit Providers was always CISCO ASR. I contributed by delivering this Proof of Concept on extending the existing Network Automation Implementation to JunosOS operating system.
            `,
            results: `Transit BGP peering needs to be configured in a modular fashion. This means that the prefix-list needs to be configured as an independent module (config section) that can be updated/changed independently of the whole peering config.
            
            It should be considered whether one failure (failed to update one PE router) should cause the rollback of the changes on other routers (either all routers get updated or none of them).
            
            Before the script does any changes – configuration should be locked on the routers to prevent committing other Admin's changes halfway through.
            `,
            tekeaway: `To implement automation into an ISP's network - the configuration across network devices has to be unified to some extent. Achieving repetitiveness and repeated results of manipulating router configuration require additional thoughts to simplify the Internet Connectivity design of an ISP. Leaving this step out while planning to automate the network will return in an exponential increase in complexity of the Python3 code.
            
            Passing operational tasks to the "machines" is another way of achieving efficiency in operating a network. Large networks can be run by fewer Network Engineers as opposed to ISP's without optimized management solutions. The introduction of scripts lowers the OpEx costs of running a network, allowing businesses to expand faster and be more profitable.
            `
        },
        project2: {
            title: `MPLS L3VPN Advanced Routing`,
            subtitle: `Enterprise :: Network Expansion`,
            description: `MPLS L3VPN, Advanced Enterprise routing solution that interconnects cloud, on-prem and Partner Network resources.`,
            overview: `Advanced Routing solution for Enterprises. Access to MS Azure Cloud through Express Route, MPLS L3VPN routes redistribution between Cloud, On-Prem and Partner VRF’s. An HLD and LLD document that elaborates on inter-VRF connectivity that follows the Shared Services connectivity model.`,
            challenge: `ACME Technologies requires to connect their MS Azure Cloud Workload with On-Prem DC and Office networks. Previously, the connectivity between On-Prem and Cloud workloads took place over the Internet. The project aims to introduce a new connection called MS Azure Express Route.

            ACME’s business partner – called “Partner Network” requires access to the same Cloud resources. Because of security reasons – the Partner should not have access to ACME DC and OFFICE network. It was decided to keep the routes of all three entities in separate VRF’s.

            To cater for the requirements, an MPLS L3VPN Inter-VRF design was proposed to keep the networks separate but allowed for easy route redistribution between the Enterprise and Shared Services networks.
            `,
            contribution: `ACME Technologies is already an MS Azure subscriber, although the resources are reachable via the Internet. Additionally, a Business Partner expressed the willingness to get access to the same resources as ACME.

            My contribution was to order xConnects from Connectivity Provider, configure the Express Route functionality and build links towards the Partner network. I liaised with the Connectivity Provider to get the correct setup on the PE routers and coordinated the patching. As the Project Owner – I worked with the Stakeholders, gathered the requirements, obtained approvals, designed and implemented the solution by following the company’s standards. These actions included working with Aquanox Connectivity Provider, vendors and Partner Network engineers.

            I closed off the project by handing over the documentation and escalation matrix to the NOC for support and maintenance activities.
            `,
            results: `The MPLS L3VPN connectivity project was implemented successfully, allowing or traffic passing securely over dedicated links within a DC instead of previously traversing the Internet. Partner Network achieved diversification by utilizing two separate routes (Terrestrial Fibre and Submarine Capacity) and connecting to two different ACME edge routers.

            ACME Technologies designed the connectivity by keeping High Availability on the network and device level. Separate circuits, separate PE routers and different fire zones with a DC were maintained to meet the HA requirements.

            Partner Network connects over Interprovider Layer 3 Option A, where his prefixes are housed within a dedicated VRF. Routes from this VRF are explored into the MS Azure VRF, where they are forwarded further to the MS Azure Cloud network. The same goes for the DC and Office VRF. The solution was designed in a way where the DC/Office prefixes are never leaked into the Partner Network and vice versa. The solution is now in production and is further developed as new requirements are brought to light.
            `,
            tekeaway: `Bespoke MPLS L3VPN build is more straightforward when it’s built from scratch, opposed to re-doing existing L3 VPN’s that are already in production. The fundamental elements like Rout Target choice will differ whether the VRF’s will be “in isolation” or interconnected like in the “Shared Services” VRF interconnectivity model.

            When buying advanced connectivity services (MS Azure Express Route) – vendors make their Subject Matter Experts (SME’s) available to be consulted on how the technology works. It’s highly advised to loop the SME early to avoid delay and adequately understand the technical requirements.

            Working with multi-vendor equipment imposes a challenge to deliver the same advanced functionality on both the IOS-XR and JunosOS PE routers. Both platforms require advanced knowledge of the platform’s functionality and syntax. The solution was deployed on six PE routers, where five of them were JunosOS, and one was IOS-XR. The development work took equal time to develop a solution for one IOS-XR as it took for five JunosOS routers.
            `
        },
        project3: {
            title: `Turnkey Infrastructure Upgrade`,
            subtitle: `Enterprise :: Professional Services`,
            description: `A Case Study about replacement of customer network as part of the global Network Infrastructure Upgrade Project.`,
            overview: `Customer Branch is part of the global Network Infrastructure Upgrade Project. New hardware and services need to be installed and provisioned; users needed to be migrated to the new network. In short – the site required to undergo a "fork-lift" replacement and upgrade of the existing network infrastructure. Once done, obsolete services needed to be ceased and outdated equipment needed to be decommissioned and recycled.
            
            UBS Campus Network follows the Hub and Satellite topology. The visited site was classified as a Medium Branch Office. One floor, four racks of equipment, 80 users, 5 AP's, redundant WAN links.
            `,
            challenge: `This assignment required two visits to the Customer Branch Office. The first visit was about verifying the inventory, staging and installing the equipment. The challenge was to commission the new equipment and to ensure the WAN circuits come up before the Maintenance Window of the Service Provider closes.
            
            During the second visit, the challenge was to migrate users and the end-systems to the new hardware and conduct the User Acceptance Testing before COB.
            `,
            contribution: `The Amsterdam UBS site was a turnkey project. The hardware was shipped pre-configured, with slotted in line cards and optic. During my time at the site, a local Assisting Engineer accompanied me to help out with racking and cabling tasks. During my role as the Leading Engineer, my contribution was:
            
            (during 1st visit) to make an inventory list and check-off the received shipment against the BoM, coordinate the installation process to ensure all routers, switches and AP's are connected and reachable according to the given diagrams.
            
            (during 2nd visit) to migrate the Voice Gateway, Servers, Wired and Wireless Users onto the new network infrastructure. During this visit – I liaised closely with various on-site stakeholders like Partner & other Third-Party support (access control/CCTV), Customers (BYOD/customer support), local IT Team (handover/hardware recycling policy).
            
            During both visits, I was responsible for reporting, providing updates and smart hands support for any called out issues. As per my span of control – I decided on tasks and troubleshooting steps of the Assisting Engineer.
            `,
            results: `My schedule was very tight during both visits at the Amsterdam office. Knowing the runbook and project plan by heart was of paramount importance to produce and submit every deliverable accurately and on time. Both days required me to dial in into a WebEx bridge where I was in touch with remote support to call-out any issues on-site, take instruction from the remote team, and troubleshoot along. I provided updates on the cutover window's opening at 25%, 50%, 75% and 100% checkpoints.
            

            On the day before carrying over the cutover, I checked-in on-site and met with fellow engineer and UBS site contact. After familiarizing myself with the Comms room, I dialled in into WebEx and uploaded pre-work pictures. The next step was to locate the shipment and check the inventory against packing slip and BoM. Assisting Engineer unboxed and staged the equipment, where I verified the installed devices against Rack Build Information. We reached 25% completion.
            
            
            I identified and labelled patching from telco wall. Assisting Engineer traced patch cables and updated cut-sheet. The next step was to install routers, switches, Terminal Server, AP's, NFX appliances, and connect them according to the core turn-up installation document. By doing this – we reached 50% completion.
            
            
            On the cutover day, I assisted the customer with carrying out a baseline User Acceptance Testing (UAT) and talked through the cutover process. After submitting the UAT on the bridge – I had to wait for the go/no-go call. After we got the go-ahead from the remote support, we started the cutover activity, moving servers, access control systems and users to the new infrastructure. After going through this section of the runbook – we reached 75% completion.
            `,
            tekeaway: `Final stretch! At this part of the cutover, I created the as-built documentation package, while resolving any reported issues by the users. Once all issues have been resolved, the site contact conducted a final UAT, followed by shutting down existing MDF equipment and detaching old Access Point's. Assisting Engineer decommissioned the old equipment according to recycling policy, whipped the config and prepared for dispatch. I obtained the final signoff from site contact, uploaded a complete packet of deliverables onto WebEx. After cleaning-up and uploading post-work pictures – we reached 100% completion and been released from the site.`
        },
        project4: {
            title: `CoPP for Core Network Devices`,
            subtitle: `ISP::Network Upgrade`,
            description: `Core router re-configuration project. Improving security and resiliency of ISP backbone routers.`,
            overview: `Core Router re-configuration project, an initiative to secure and add resiliency to the control and management plane of backbone routers.`,
            challenge: `The carrier network was hammered continuously by sniffers, ICMP packets, DoS and unauthorized log-in attempts. All of this activity caused high CPU usage. To help protect against that CPU utilization - CoPP needed to be rolled out as part of the company project road-map.
            
 
            The Core Network consists of routers of different architecture and different vendors. The one size fit all solution was not applicable. Each make and model needed to be addressed and approached individually.
            
            
           An additional challenge was to identify the authorized systems, IPs, and protocols that communicate with the routers' control and management plane. The audit result gave the required informational input to configure the policers, filters, and classes of traffic.
           `,
            contribution: `By default, the ASR platform already limits exception traffic in hardware (LTPS). IOS-XR already rate-limit the exception traffic. The only tweaking of this mechanism was to whitelist the valid sources of control and management traffic. Unlike the ASR (protected by LTPS), - remaining platforms take a hybrid/no protection approach.
            
 
            My task was to harden the JunosOS and IOS to a similar level like IOS-XR was. I contributed to this project by identifying valid network traffic and creating a list of valid pollers in the first place. Secondly - I crafted filters/ACL's and applied rate-limiters to the control/management traffic. Finally, during a series of maintenance windows - the CoPP functionality was successfully rolled out on Cisco ISR and Juniper MX platforms.
            `,
            results: `After solving all the challenges - two templates were prepared of router configs that protected the control and management plane from unwanted traffic, allowing access to all the valid monitoring and management systems. Unwanted traffic and attack attempts continued to reach the core, but they got rate-limited/discarded before reaching router processes and daemons for handling. The rolled-out CoPP took off the strain from the carrier routers' memory and CPU, allowing them to carry more valid protocol state. The business fulfilled their need to secure their assets.`,
            tekeaway: `<ul><li>despite traffic coming from a legitimate source - we don't have the certainty that the system hasn't got compromised. Not only whitelisting but also rate-limiting needs to be applied.</li>
            <li>IS-IS is an L2 protocol. Layer 3/inet filters have no application when securing the control plane. This fact makes IS-IS "secure" by design.</li>
            <li>an implicit permit has been added (and policed) to the firewalls/ACL's to cater for the undefined (but still desired) traffic. This practice avoids breaking the functionality for the "missed" protocols.</li>
            <li>direct and connected subnets are whitelisted to allow management access from directly connected devices.</li>
            <li>Management/control traffic level varies on a network by network basis. After applying a policer to exception traffic, the policed protocols' performance needs to be carefully verified.</li></ul>
            `
        },
        project5: {
            title: `MPLS L2VPN Service`,
            subtitle: `ISP::Service Delivery`,
            description: `A Layer 2 Circuit, provisioned for an existing customer. A P2P L2VPN from a Branch Office to Company's HQ.`,
            overview: `A Layer 2 Circuit, provisioned for an existing customer. A point-to-point L2VPN from a newly opened Branch Office in Kenya, Nairobi to Headquarters in Nigeria, Abuja.`,
            challenge: `The customer has their Branch Offices (BO) spreader across southern Africa. Their network follows a Hub and Spoke topology. While their HQ border router resides in the same PoP as Fedora Telecom, Branch Offices are located in industrial districts. Each time connecting a BO – new agreement between Fedora and the Partner owning the local network needs to be worked out.
            
            A typical technical hurdle to overcome was the mapping of VLAN ID and to get the size of the L2 MTU increased. Customer application traffic couldn’t be fragmented; therefore, the circuit MTU needed to be big enough to pass data as it was sent. Partner Equipment at the local loop did not allow for jumbo frames. Additionally - tunnelling/trucking techniques used by Partners introduced additional overhead.
            `,
            contribution: `I worked out the details with the customer on the VLAN and PoP information for his new site. This served as informational input to get in touch with a partner company that provided the Last Mile segment (E-Access in the MEF framework terminology). I ordered the intra-DC xConnect and liaised with the Partner Network tech representative to agree on the handoff method towards the Branch Office. Finally, I wrote the procedure on how to provision services across the Partner Network to remote customer sites.`,
            results: `Delivery of the circuit has been documented and polled into the monitoring system. After getting confirmation from the customer, that the circuit is operational – Fedora Telecom documented Ubuntu’s test results with the intent to establish a baseline for the performance of the circuit. Fedora Telekom recorded packet loss and PING response times for future reference. This turned out to be good practice and helpful information in troubleshooting issues resulting from transmitting data across long distances.`,
            tekeaway: `<ul><li>the better the class of the last mile equipment, the easier is to get the circuit to be delivered with the required specification. This goes as well for the in-build tools and helpful features during a tshoot session.</li>
            <li>L2 services are more challenging to tshoot and monitor than L3VPN based connection. Ethernet OAM and Connectivity Fault Management supported/implemented only by a handful of Vendors.</li>
            <li>Long-distance networks are subject to degraded link bandwidth, known as the outcome of a Bandwidth Delay Product. An additional chapter (Bandwidth Delay Product phenomenon) was added into the NOC Tshoot Scrip to help the NOC team diagnose and solve customer tickets faster.</li></ul>
            `
        },
        project6: {
            title: `Senegal New PoP Build`,
            subtitle: `ISP :: Network Expansion`,
            description: `ISP new PoP build. Expansion of Telco’s network connectivity by levering transoceanic submarine cable system.`,
            overview: `ISP new PoP build. Expansion of Africa Telecom by enhancing core network connectivity through levering 10Gbps transoceanic and regional submarine cable system. An HLD and LLD document that adds inter-continental capacity and opens up the Business to serve new markets.`,
            challenge: `A P/PE router was required to cater for the desired throughput and Edge Device functionality. A network device was needed that is capable of IP transit, peering and terminating submarine capacity (IPv4/IPv6, MPLS, VPNv4). All this – within the allocated (Small PoP) budget.
            
            The new PoP needed to be delivered before the end of the next quarter; hence the project was put on a tight schedule. The challenge was to prepare the config and expedite the Juniper MX router's dispatch and schedule the Field Engineer visit to get the equipment racked and installed.
            
            The Senegal PoP needed to connect over ACE and SAT-3/WASC cable systems to Africa Telecom Large PoP's. Procurement and Transmission Team required to procure the submarine capacity from adequate Consortium.
            `,
            contribution: `Africa Telecom operates in the industry for years. AT is building PoPs and is expanding its network for years. The generic config templates are for the whole group to use and to develop further. 
            
            My contribution was to take a generic config template and amend/extend it to provision a new router in a new Africa Telecom Group PoP. I was leading this global project from development to implementation. As the Project Engineer – I recommended the BoM and P/PE make and model of the hardware. This included obtaining quotes from the selected hardware and xConnect fees. On the flag day – I gave instructions to Field Engineer in Senegal and Remote Hands Engineers in Lisbon and Luanda. I closed off the project by conducting in-depth failover testing and placing the new router into AT Monitoring and TACACS+ Systems.
            `,
            results: `The new PoP became a HUB for offering AT services in Senegal and adjacent countries. The Business has received some interest in terrestrial connectivity services from 2 neighbouring regions. ACE subsea cable lands in Senegal, and this is the natural point of interconnecting into Europe. Having a presence in this region – gave the Business unparalleled competitive advantage over the competition.
            
            AT achieved additional benefit in the form of path redundancy – another 10Gbps link that expanded the core network capacity for traffic from Europe to Angola, as well as diversity in cable systems and transport network providers.
            
            The router was installed, configured, integrated into the network and failover tested within one DC visit.
            `,
            tekeaway: `In-depth stress/failover testing of the installed router is mandatory before the in-house Field Engineer leaves the DC. AT doesn't have an OpCo in Senegal; therefore, it's highly advised to simulate every possible failure scenario.
            
            A best practice is to assign the design task to one engineer and implementation to a different engineer. Leaving both tasks to one engineer leaves space for errors and omissions in config and implementation steps. With projects of such scale – scrutiny is highly desired while peer-reviewing the design document. This best practice increases the likelihood of a swift and successful implementation of the project.
            `
        }
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default reducer;