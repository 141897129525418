import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Section from '../../hoc/Section/Section';
import Button from '../../components/UI/Button/Button';
import Footer from '../../components/Footer/Footer';

import uefa from '../../assets/brands/uefalogo.svg';
import pg from '../../assets/brands/pg.com.svg';
import ubs from '../../assets/brands/ubs.svg';
import gsk from '../../assets/brands/gsk.svg';
import orange from '../../assets/brands/orange.svg';
import ldex from '../../assets/brands/lex.svg';
import liquid from '../../assets/brands/liquid.svg';

import './Home.scss';
import * as actions from '../../store/actions/';

function Home(props) {
  
    useEffect(()=>{
      props.haseRouteMounted(true);
      if(props.mountedClasses === "App App-Will-Mount" && props.transitionPageClass === "transitionPage-active") {
        props.setTransitionPageClass("transitionPage-disable")
      };
      let timer1;
      let timer2;

      if(props.firstLoad === true) {
          timer1 = setTimeout(() => {
              props.isFirstLoad(false);
              props.changeFirstLoadClasses("firstLoadPage firstLoadAnimation")
          }, 400)
      }
      timer2 = setTimeout(()=>{
        props.setTransitionPageClass(" ");
      },500)
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      }
    }, [props])

    const goingUp = useRef();
    const br=(<div style={{'height':'14px'}}/>);
    
    return (
      <div className={props.mountedClasses}>
        <header>
        <Section classMain="header" type="type1" refProps={goingUp}>
            <div className="home-header">
                <h3>Contractor Available for Hire</h3>{br}
                <h2>I build networks for large scale Internet Service Providers.{br}I help businesses to grow and become relevant in today’s competitive market.</h2>{br}
                <p>Tomasz is an accomplished Senior Network Engineer currently based in London, UK.</p>{br}
                <Button type="type1" pathType="type1" path="/about">I WANT TO KNOW MORE</Button>
            </div>
            <div className="Notebook" />
        </Section>
        </header>
        <Section classMain="brands" type="type2">
          <img src={uefa} alt="UEFA"/>
          <img src={pg} alt="P-G"/>
          <img src={ubs} alt="UBS"/>
          <img src={gsk} alt="GSK"/>
          <img src={orange} alt="Orange"/>
          <img src={ldex} alt="LDEX Group"/>
          <img src={liquid} alt="Liquid Group"/>
        </Section>
        <Footer elementRef={goingUp}/>
      </div>
    );
}

const mapStateToProps = state => {
  return {
    firstLoad: state.ui.firstLoad,
    mountedClasses: state.ui.mountedClass,
    transitionPageClass: state.ui.transitionPageClass,
    mountingPageDir: state.ui.pageHaseMounted
  }
}

const mapDispatchToProps = dispatch => {
  return {
    isFirstLoad: (isFirstLoad) => dispatch(actions.isFirstLoad(isFirstLoad)),
    setTransitionPageClass: classes => dispatch(actions.setTransitionPageClass(classes)),
    haseRouteMounted: bool => dispatch(actions.isRouteMounted(bool)),
    changeFirstLoadClasses: (classes) => dispatch(actions.changeFirstLoadClasses(classes))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);