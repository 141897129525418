import React, { useEffect, useState } from 'react';
import './Layout.scss';
import './Media.scss';

import Aux from '../Auxillian';

import Navigation from '../../components/Navigation/Navigation';
import Burger from '../../components/Burger/Burger';
import FirstLoadPage from '../../components/UI/FirstLoadPage/FirstLoadPage';
import TransitionPage from '../../components/UI/TransitionPage/TransitionPage';

function Layout(props) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [windowWidth])

  let navigation = <Navigation />

  if (windowWidth < 1100) {
    navigation = <Burger />
  }

    
  let layout = <Aux>
      {navigation}
      <TransitionPage>
          {props.children}
      </TransitionPage>
  </Aux>

  return (
    <main className="main">
      <FirstLoadPage />
      {layout}
    </main>
  );
}

export default Layout;