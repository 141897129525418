import React, { Suspense } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.scss';

import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';

const About = React.lazy(() => {
  return import('./containers/About/About')
})
const Skills = React.lazy(() => {
  return import('./containers/Skills/Skills')
})
const Portfolio = React.lazy(() => {
  return import('./containers/Portfolio/Portfolio')
})
const Consulting = React.lazy(() => {
  return import('./containers/Consulting/Consulting')
})
const Blog = React.lazy(() => {
  return import('./containers/Blog/Blog')
})
const Contact = React.lazy(() => {
  return import('./containers/Contact/Contact')
})

function App({location}) {

  let routes = (
      <Switch location={location}>      
       <Route path='/about' render={(props) => <About {...props} />}/>
       <Route path='/skills' render={(props) => <Skills {...props} />}/>
       <Route path='/portfolio' render={(props) => <Portfolio {...props} />}/>
       <Route path='/consulting' render={(props) => <Consulting {...props}/>}/>
       <Route path='/blog' render={(props) => <Blog {...props}/>}/>
       <Route path='/contact' render={(props) => <Contact {...props}/>}/>
       <Route exact path="/" component={Home}/>
       <Redirect to="/404" />
      </Switch>
  )

  return (
    <div className="App-main">
      <Layout>
        <Suspense fallback={" "}>{routes}</Suspense>
      </Layout>
    </div>
  );
}

export default withRouter(App);
