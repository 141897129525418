import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Logo from '../../assets/svg/logo_horizontal';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import SocialMedia from '../Navigation/SocialMedia/SocialMedia';

import * as colors from '../../hoc/shared/colors';
import * as actions from '../../store/actions/';

import './Burger.scss';

function Burger(props) {

    const checkDirNumber = pathname => {
        if(pathname === "/") {
            return 1;
        } else if(pathname === "/about") {
            return 2;
        } else if(pathname === "/skills") {
            return 3;
        } else if(pathname === "/portfolio") {
            return 4;
        } else if(pathname === "/consulting") {
            return 5;
        } else if(pathname === "/blog") {
            return 6;
        } else if(pathname === "/contact") {
            return 7;
        } else {
            return 0;
        }
    }

    let timer1;
    let timer2;

    const clicked = (e, dir) => {
        window.scrollTo(0,0);
        e.preventDefault();
        props.mountingPageDir(" ");
        
        const pathname = history.location.pathname;
        if(pathname !== dir) {

            if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Dec")
                props.mountingPageDir("Dec");
            } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                props.changeMountedClasses("App App-Will-Unmount-Inc")
                props.mountingPageDir("Inc");
            }
            props.setTransitionPageClass("transitionPage-active");
            props.changeNavFontColor(colors.mainColor1);

            clearTimeout(timer1, timer2);

            timer1 = setTimeout(() => {
                history.push(dir)
                if(checkDirNumber(pathname) > checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Dec")
                } else if (checkDirNumber(pathname) < checkDirNumber(dir)) {
                    props.changeMountedClasses("App App-Will-Mount-Inc")
                }
            timer2 = setTimeout(()=>{
                props.changeNavFontColor(colors.mainColor3);
            },400)
            },500)
        }
    }

    const [menuHeight, setMenuHeight] = useState("-100vh")

    const history = useHistory();

    const menuStyle = {
        top: menuHeight,
        left: 0
    }

    const home = (
        <a href="/"  onClick={e => {
            setMenuHeight("-100vh");
            clicked(e, '/');
            }} >
            <Logo
                classMain="logo_horizontal"
                class1="logo_horizontal_st0"
                class2="logo_horizontal_st1"
                color1={colors.mainColor5}
                color2={props.navFontColor}
                alt="Tomasch Schwiertz"/>
        </a>
    )

    return <div className="burger">
        {home}
        <div className="burgerIco" onClick={()=>setMenuHeight(0)}>
            <div></div>
        </div>
        <div className="burgerMenu" style={menuStyle} onClick={()=>setMenuHeight("-100vh")}>
            <NavigationItems /><SocialMedia />
        </div>
    </div>
}

const mapStateToProps = state => {
    return {
        navFontColor: state.ui.navFontColor
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeMountedClasses: classes => dispatch(actions.changeMountedClasses(classes)),
        setTransitionPageClass: classes => dispatch(actions.setTransitionPageClass(classes)),
        isRouteMounted: bool => dispatch(actions.isRouteMounted(bool)),
        changeNavFontColor: color => dispatch(actions.changeNavFontColor(color)),
        mountingPageDir: dir => dispatch(actions.pageHaseMountedDir(dir))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Burger);