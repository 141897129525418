import React from 'react';
import Section from '../../hoc/Section/Section';
import Button from '../UI/Button/Button';
import LogoVertical from '../../assets/svg/logo_vertical';
import * as colors from '../../hoc/shared/colors';

function Footer (props) {
    return (
        <Section classMain="footer" type="type4">
          <Button type="type1" pathType="type2" elementRef={props.elementRef} classMain="arrowButton" />
          <LogoVertical classMain="logoVertical" color1={colors.mainColor4} color2={colors.mainColor2} color3={colors.mainColor2}/>
          <p className="footer_questions">Any questions?<br/> Contact me and I would be happy to answer them!</p>
          <Button type="type1" pathType="type1" path="/contact" classMain="footerButton">CONTACT</Button>
          <p><span style={{color: colors.mainColor4}}>©2021</span> Tomasz Schwiertz - MADE BY <span style={{color: colors.mainColor4}}>KAMIL JANOSZKA</span></p>
        </Section>
    )
}

export default Footer;