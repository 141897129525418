import * as actionTypes from './actionTypes';

export const isFirstLoad = bool => {
    return {
        type: actionTypes.IS_FIRST_LOAD,
        bool: bool
    }
}
export const changeFirstLoadClasses = classes => {
    return {
        type: actionTypes.CHANGE_FIRST_LOAD_CLASSES,
        classes: classes
    }
}

export const changeMountedClasses = classes => {
    return {
        type: actionTypes.CHANGE_MOUNTED_CLASSES,
        classes: classes
    }
}
export const setTransitionPageClass = classes => {
    return {
        type: actionTypes.SET_TRANSITION_PAGE_CLASS,
        classes: classes
    }
}
export const isRouteMounted = bool => {
    return {
        type: actionTypes.IS_ROUTE_MOUNTED,
        bool: bool
    }
}
export const changeNavFontColor = color => {
    return {
        type: actionTypes.CHANGE_NAV_FONT_COLOR,
        color: color
    }
}
export const pageHaseMountedDir = dir => {
    return {
        type: actionTypes.PAGE_HASE_MOUNTED_DIR,
        dir: dir
    }
}