import React from 'react';
import * as colors from '../shared/colors';
import './Section.scss';

function Section(props) {

    const style = (type) => {
        switch(type) {
            case ("type1"):
                return {
                    backgroundColor: colors.mainColor1,
                    color: colors.mainColor3
                };
            case ("type2"):
                return {
                    backgroundColor: colors.mainColor2,
                    color: colors.mainColor3
                };
            case ("type3"):
                return {
                    backgroundColor: colors.mainColor4,
                    color: colors.mainColor3
                };
            case ("type4"):
                return {
                    backgroundColor: colors.mainColor3,
                    color: colors.mainColor2
                };
            default:
                return {
                    backgroundColor: colors.mainColor1,
                    color: colors.mainColor3
                }
        }
    }

    let title = null;

    return (
        <section style={style(props.type)} ref={props.refProps} className={"section " + props.classMain}>
            {title}
            {props.children}
        </section>
    )
}

export default Section;