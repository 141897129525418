import React from 'react';
import { connect } from 'react-redux';

import Aux from '../../../hoc/Auxillian';
import Twitter from '../../../assets/svg/Twitter';
import Instagram from '../../../assets/svg/Instagram';
import LinkedIn from '../../../assets/svg/LinkedIn';

function SocialMedia(props) {
    return (
        <Aux>
            <a href="https://www.linkedin.com/in/tomaszschwiertz/" target="_blank" rel="noopener noreferrer"><LinkedIn classMain="socialIcon1" classPath="socialIconPath" color={props.navFontColor} alt="visit me on LinkedIn!"/></a>
            <a href="https://twitter.com/tomaszschwiertz" target="_blank" rel="noopener noreferrer"><Twitter classMain="socialIcon2" classPath="socialIconPath" color={props.navFontColor} alt="visit me on twitter!"/></a>
            <a href="https://www.instagram.com/tomaszschwiertz/" target="_blank" rel="noopener noreferrer"><Instagram classMain="socialIcon3" classPath="socialIconPath" color={props.navFontColor} alt="visit me on Instagram!"/></a>
        </Aux>
    )
}

const mapStateToProps = state => {
    return {
        navFontColor: state.ui.navFontColor
    }
}


export default connect(mapStateToProps)(SocialMedia);