import React from 'react';

export default function LogoVertical(props) {

const st0 = {
	className: props.class1,
	style: {fill: props.color1}
}
const st1 = {
	className: props.class2,
	style: {fill: props.color2}
}
const st2 = {
	className: props.class2,
	style: {fill: props.color3}
}
	return (
<svg className={props.classMain} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 510.24 283.46" style={{'enableBackground':'new 0 0 510.24 283.46'}} xmlSpace="preserve">
<style type="text/css">
</style>
<g>
	<g>
		<path {...st0} d="M37.69,268.79c1.76,2.5,3.18,4.72,4.28,6.66h0.11c-0.15-3.18-0.22-5.5-0.22-6.95v-11.09h4.48v24.49h-4.7
			l-7.98-11.27c-1.4-1.95-2.85-4.21-4.36-6.79H29.2c0.15,3,0.22,5.33,0.22,6.97v11.09h-4.48v-24.49h4.7L37.69,268.79z"/>
		<path {...st0} d="M64.42,278.15h12.04v3.75H59.93v-24.49H75.8v3.75H64.42v6.33h9.77v3.75h-9.77V278.15z"/>
		<path {...st0} d="M96.39,281.9H91.9v-20.74H84v-3.75h20.28v3.75h-7.89V281.9z"/>
		<path {...st0} d="M127.54,263.85c-0.47,2.28-1.02,4.64-1.65,7.06l-2.74,10.98h-4.47l-7.78-24.49h4.78l2.95,9.75
			c0.89,3.06,1.61,5.83,2.16,8.31h0.11c0.54-2.66,1.16-5.39,1.87-8.18l2.53-9.88h4.61l2.53,9.88c0.71,2.77,1.33,5.5,1.87,8.18h0.11
			c0.67-2.93,1.39-5.7,2.16-8.31l2.95-9.75h4.78l-7.78,24.49h-4.47l-2.75-10.98c-0.62-2.48-1.17-4.83-1.65-7.06H127.54z"/>
		<path {...st0} d="M176.61,269.65c0,1.87-0.27,3.58-0.8,5.13c-0.53,1.56-1.31,2.9-2.33,4.02c-1.02,1.12-2.27,1.99-3.75,2.62
			c-1.48,0.62-3.17,0.93-5.06,0.93c-1.89,0-3.58-0.31-5.06-0.93c-1.48-0.62-2.73-1.5-3.75-2.62c-1.02-1.12-1.79-2.46-2.32-4.02
			c-0.53-1.56-0.8-3.27-0.8-5.13c0-1.87,0.27-3.58,0.8-5.13c0.53-1.56,1.31-2.9,2.32-4.02c1.02-1.12,2.27-1.99,3.75-2.62
			c1.48-0.62,3.17-0.93,5.06-0.93c1.89,0,3.58,0.31,5.06,0.93c1.48,0.62,2.73,1.5,3.75,2.62c1.02,1.12,1.79,2.46,2.33,4.02
			C176.34,266.08,176.61,267.79,176.61,269.65z M171.97,269.65c0-1.49-0.18-2.79-0.55-3.91c-0.37-1.12-0.87-2.05-1.52-2.79
			c-0.65-0.74-1.42-1.3-2.31-1.67c-0.89-0.36-1.87-0.55-2.93-0.55c-1.06,0-2.04,0.18-2.93,0.55c-0.89,0.37-1.66,0.92-2.31,1.67
			c-0.65,0.74-1.15,1.67-1.52,2.79c-0.37,1.12-0.55,2.42-0.55,3.91c0,1.49,0.18,2.79,0.55,3.91c0.37,1.12,0.87,2.04,1.52,2.78
			c0.65,0.74,1.42,1.29,2.31,1.67c0.89,0.37,1.87,0.56,2.93,0.56c1.06,0,2.04-0.19,2.93-0.56c0.89-0.37,1.66-0.93,2.31-1.67
			c0.65-0.74,1.15-1.67,1.52-2.78C171.79,272.44,171.97,271.14,171.97,269.65z"/>
		<path {...st0} d="M207.2,264.77c0,1.71-0.47,3.16-1.42,4.35c-0.95,1.19-2.41,2.07-4.38,2.64v0.07l7.28,10.07h-5.38l-6.66-9.48
			h-3.07v9.48h-4.48v-24.49h7.45c1.23,0,2.38,0.07,3.44,0.21c1.06,0.14,1.97,0.35,2.73,0.63c1.44,0.55,2.55,1.36,3.33,2.44
			C206.81,261.77,207.2,263.13,207.2,264.77z M195.79,268.66c1.03,0,1.89-0.05,2.61-0.14c0.71-0.09,1.31-0.23,1.8-0.41
			c0.9-0.34,1.53-0.81,1.88-1.4c0.35-0.59,0.52-1.27,0.52-2.04c0-0.66-0.14-1.24-0.41-1.75c-0.27-0.51-0.74-0.91-1.4-1.2
			c-0.43-0.19-0.96-0.34-1.61-0.43c-0.65-0.09-1.45-0.14-2.4-0.14h-3.2v7.5H195.79z"/>
		<path {...st0} d="M220.35,281.9v-24.49h4.48v11.15h0.07l9.24-11.15h5.56l-10.21,12.13l11.05,12.35h-5.75l-9.9-11.05h-0.07
			v11.05H220.35z"/>
		<path {...st0} d="M263.96,278.15H276v3.75h-16.53v-24.49h15.87v3.75h-11.38v6.33h9.77v3.75h-9.77V278.15z"/>
		<path {...st0} d="M301.24,268.79c1.76,2.5,3.18,4.72,4.28,6.66h0.11c-0.14-3.18-0.22-5.5-0.22-6.95v-11.09h4.48v24.49h-4.7
			l-7.98-11.27c-1.41-1.95-2.86-4.21-4.36-6.79h-0.11c0.15,3,0.22,5.33,0.22,6.97v11.09h-4.49v-24.49h4.7L301.24,268.79z"/>
		<path {...st0} d="M337.24,272.55h-5.49v-3.75h9.97v12.35c-0.36,0.13-0.83,0.27-1.4,0.42c-0.57,0.15-1.2,0.28-1.9,0.39
			c-0.7,0.12-1.42,0.21-2.17,0.28c-0.74,0.07-1.48,0.11-2.21,0.11c-2.2,0-4.11-0.3-5.75-0.89c-1.64-0.59-3.01-1.43-4.1-2.5
			c-1.09-1.07-1.91-2.35-2.45-3.83c-0.54-1.48-0.81-3.12-0.81-4.91c0-1.27,0.14-2.48,0.43-3.63c0.29-1.15,0.71-2.22,1.26-3.2
			c0.56-0.98,1.23-1.87,2.02-2.66c0.79-0.79,1.7-1.47,2.72-2.03c1.02-0.56,2.13-0.99,3.35-1.29c1.21-0.3,2.52-0.45,3.91-0.45
			c1.23,0,2.4,0.09,3.51,0.27c1.11,0.18,2.02,0.39,2.73,0.65v3.81c-0.95-0.31-1.91-0.54-2.88-0.7c-0.97-0.16-1.95-0.24-2.94-0.24
			c-1.33,0-2.57,0.2-3.73,0.6c-1.16,0.4-2.16,0.98-3.01,1.76c-0.85,0.78-1.52,1.73-2.01,2.86c-0.49,1.13-0.73,2.45-0.73,3.94
			c0.01,2.89,0.77,5.06,2.27,6.51c1.5,1.45,3.63,2.17,6.39,2.17c0.51,0,1.04-0.03,1.59-0.08c0.55-0.06,1.03-0.12,1.45-0.21V272.55z"
			/>
		<path {...st0} d="M354.94,281.9v-24.49h4.48v24.49H354.94z"/>
		<path {...st0} d="M385.76,268.79c1.76,2.5,3.18,4.72,4.28,6.66h0.11c-0.15-3.18-0.22-5.5-0.22-6.95v-11.09h4.48v24.49h-4.7
			l-7.98-11.27c-1.4-1.95-2.85-4.21-4.36-6.79h-0.11c0.15,3,0.22,5.33,0.22,6.97v11.09H373v-24.49h4.7L385.76,268.79z"/>
		<path {...st0} d="M412.48,278.15h12.04v3.75h-16.53v-24.49h15.87v3.75h-11.38v6.33h9.77v3.75h-9.77V278.15z"/>
		<path {...st0} d="M441.48,278.15h12.04v3.75H437v-24.49h15.87v3.75h-11.38v6.33h9.77v3.75h-9.77V278.15z"/>
		<path {...st0} d="M484.13,264.77c0,1.71-0.47,3.16-1.42,4.35c-0.95,1.19-2.41,2.07-4.38,2.64v0.07l7.28,10.07h-5.38l-6.66-9.48
			h-3.08v9.48h-4.48v-24.49h7.45c1.23,0,2.38,0.07,3.44,0.21c1.06,0.14,1.97,0.35,2.73,0.63c1.44,0.55,2.55,1.36,3.33,2.44
			C483.74,261.77,484.13,263.13,484.13,264.77z M472.71,268.66c1.02,0,1.89-0.05,2.61-0.14c0.71-0.09,1.31-0.23,1.8-0.41
			c0.9-0.34,1.53-0.81,1.88-1.4c0.35-0.59,0.52-1.27,0.52-2.04c0-0.66-0.14-1.24-0.41-1.75c-0.27-0.51-0.74-0.91-1.4-1.2
			c-0.43-0.19-0.96-0.34-1.61-0.43c-0.65-0.09-1.45-0.14-2.4-0.14h-3.2v7.5H472.71z"/>
	</g>
	<g>
		<path {...st1} d="M32.94,245.36v-49.08h-6.62v-7.45h21.55v7.45h-6.36v49.08H32.94z"/>
		<path {...st1} d="M73.97,241.92C72,244.64,68.84,246,64.51,246c-4.34,0-7.52-1.37-9.54-4.1c-2.02-2.73-3.04-6.85-3.04-12.35
			v-25.86c0-10.36,4.23-15.55,12.69-15.55c4.37,0,7.51,1.32,9.43,3.95c1.92,2.63,2.88,6.5,2.88,11.6v25.86
			C76.92,235.08,75.94,239.2,73.97,241.92z M61.36,236.59c0.5,1.31,1.54,1.96,3.1,1.96c1.56,0,2.59-0.67,3.08-2.01
			c0.49-1.34,0.74-3.63,0.74-6.89v-26.34c0-2.68-0.25-4.63-0.75-5.86c-0.5-1.23-1.49-1.85-2.96-1.85c-1.47,0-2.5,0.61-3.08,1.85
			c-0.59,1.23-0.88,3.18-0.88,5.83v26.37C60.6,232.97,60.85,235.28,61.36,236.59z"/>
		<path {...st1} d="M84.73,245.36v-56.54h10.7l8.03,41.31l7.55-41.31h10.76v56.54h-7.68v-21.07l0.32-19.34l-7.61,40.15h-6.84
			l-7.93-40.15l0.32,19.34v21.07H84.73z"/>
		<path {...st1} d="M127.71,245.36l8.06-56.54h11.37l8.58,56.54h-8.03l-1.35-10.09h-9.12L136,245.36H127.71z M145.44,228.36
			l-3.79-28.4h-0.13l-3.47,28.4H145.44z"/>
		<path {...st1} d="M160.51,229.46v-5.04h8.16v5.69c0,3.15,0.27,5.36,0.8,6.63c0.53,1.27,1.54,1.91,3.02,1.91
			c1.48,0,2.46-0.52,2.94-1.57c0.48-1.05,0.72-2.76,0.72-5.14c0-2.38-0.43-4.45-1.28-6.22c-0.86-1.77-2.22-3.61-4.08-5.54l-4.18-4.3
			c-1.95-2.03-3.46-4.15-4.55-6.33c-1.08-2.18-1.62-4.71-1.62-7.58c0-4.67,0.9-8.14,2.7-10.41c1.8-2.27,4.89-3.41,9.28-3.41
			c4.39,0,7.36,1.32,8.91,3.97c1.55,2.64,2.33,6.5,2.33,11.58v3.5h-7.93v-4.05c0-2.74-0.24-4.71-0.71-5.89
			c-0.47-1.19-1.38-1.78-2.71-1.78c-1.34,0-2.3,0.45-2.89,1.35c-0.59,0.9-0.88,2.25-0.88,4.05s0.3,3.23,0.9,4.3
			c0.6,1.07,1.68,2.44,3.24,4.11l4.66,5.04c2.33,2.53,4.1,5.09,5.29,7.68c1.19,2.59,1.78,5.42,1.78,8.48c0,5.29-0.89,9.2-2.67,11.72
			c-1.78,2.53-4.89,3.79-9.33,3.79c-4.44,0-7.54-1.41-9.28-4.22C161.38,238.96,160.51,234.85,160.51,229.46z"/>
		<path {...st1} d="M189.61,245.36v-7.65l11.73-41.47h-10.67v-7.42h19.69v6.97l-11.72,42.15h11.47v7.42H189.61z"/>
		<path {...st1} d="M227.39,229.46v-5.04h8.16v5.69c0,3.15,0.27,5.36,0.8,6.63c0.53,1.27,1.54,1.91,3.02,1.91
			c1.48,0,2.46-0.52,2.94-1.57c0.48-1.05,0.72-2.76,0.72-5.14c0-2.38-0.43-4.45-1.28-6.22c-0.86-1.77-2.22-3.61-4.08-5.54l-4.18-4.3
			c-1.95-2.03-3.46-4.15-4.54-6.33c-1.08-2.18-1.62-4.71-1.62-7.58c0-4.67,0.9-8.14,2.7-10.41c1.8-2.27,4.89-3.41,9.28-3.41
			c4.39,0,7.36,1.32,8.91,3.97c1.55,2.64,2.33,6.5,2.33,11.58v3.5h-7.93v-4.05c0-2.74-0.24-4.71-0.71-5.89
			c-0.47-1.19-1.38-1.78-2.71-1.78c-1.34,0-2.3,0.45-2.89,1.35c-0.59,0.9-0.88,2.25-0.88,4.05s0.3,3.23,0.9,4.3
			c0.6,1.07,1.68,2.44,3.24,4.11l4.66,5.04c2.34,2.53,4.1,5.09,5.28,7.68c1.19,2.59,1.78,5.42,1.78,8.48c0,5.29-0.89,9.2-2.67,11.72
			c-1.78,2.53-4.89,3.79-9.33,3.79c-4.44,0-7.54-1.41-9.28-4.22C228.26,238.96,227.39,234.85,227.39,229.46z"/>
		<path {...st1} d="M278.53,242.06c-1.95,2.62-5.01,3.94-9.19,3.94s-7.3-1.4-9.36-4.19c-2.07-2.8-3.1-6.82-3.1-12.06v-24.64
			c0-8.42,2.2-13.67,6.62-15.77c1.63-0.77,3.6-1.16,5.91-1.16c7.95,0,11.92,5.01,11.92,15.04v7.77H273v-7.23
			c0-2.91-0.25-5-0.74-6.25c-0.49-1.25-1.46-1.88-2.89-1.88c-1.43,0-2.43,0.66-2.99,1.99c-0.56,1.33-0.83,3.36-0.83,6.1v26.4
			c0,3.04,0.27,5.22,0.8,6.52c0.53,1.31,1.51,1.96,2.92,1.96c1.42,0,2.39-0.66,2.93-1.99c0.53-1.33,0.8-3.52,0.8-6.59v-8.1h8.45v8
			C281.45,235.4,280.48,239.44,278.53,242.06z"/>
		<path {...st1} d="M288.45,245.36v-56.54h8.61v22.87h7.71v-22.87h8.61v56.54h-8.61v-26.28h-7.71v26.28H288.45z"/>
		<path {...st1} d="M326.46,245.36l-6.91-56.54h7.71l4.15,40.28l5.27-40.28h6.94l5.3,40.28l3.66-40.28h7.74l-6.49,56.54h-8.58
			l-5.01-37.59l-5.2,37.59H326.46z"/>
		<path {...st1} d="M366.77,245.36v-56.54h8.61v56.54H366.77z"/>
		<path {...st1} d="M384.18,245.36v-56.54h18.95v7.42h-10.34v16.45h9.83v7.26h-9.83v17.99h10.73v7.42H384.18z"/>
		<path {...st1} d="M421.77,218.79l-2.76-0.13v26.7h-8.61v-56.54h14.23c2.96,0,5.16,0.59,6.62,1.78
			c1.46,1.19,2.41,2.85,2.88,4.98c0.46,2.13,0.69,4.92,0.69,8.35c0,3.44-0.36,6.05-1.09,7.85c-0.73,1.8-2.08,3.01-4.05,3.63
			c1.95,0.34,3.31,1.66,4.08,3.95c0.77,2.29,1.15,7.06,1.15,14.31c0,7.25,0.11,11.14,0.32,11.68h-8.61
			c-0.22-0.43-0.32-7.19-0.32-20.27c0-2.51-0.3-4.17-0.9-5C424.8,219.27,423.59,218.83,421.77,218.79z M419.03,211.98h3.44
			c1.09,0,1.9-0.25,2.43-0.74c0.52-0.49,0.89-1.34,1.09-2.54c0.2-1.2,0.31-3.03,0.31-5.49v-0.35c0-3.06-0.26-5.06-0.77-6.01
			c-0.51-0.94-1.7-1.41-3.57-1.41h-2.92V211.98z"/>
		<path {...st1} d="M445.73,245.36v-49.08h-6.62v-7.45h21.55v7.45h-6.36v49.08H445.73z"/>
		<path {...st1} d="M464.33,245.36v-7.65l11.73-41.47h-10.67v-7.42h19.69v6.97l-11.72,42.15h11.47v7.42H464.33z"/>
	</g>
	<g>
		<g>
			<path {...st2} d="M319.42,121.01c0,0.73-0.35,1.42-0.94,1.87l-59.71,28.73l-0.08-0.01l-0.38,0.27
				c-0.83,0.32-1.82,0.24-2.58-0.22l-1.65-1h-0.38l-21.62-10.37l-0.01,0.02l-50.38-23.48v4.2c0,0.69,0.06,1.39,0.17,2.07
				c0.04,0.39,0.1,0.82,0.2,1.29c0.89,4.02,3.31,7.48,6.82,9.75l60.46,29.06c2.4,1.36,5.11,2.07,7.88,2.07
				c2.05,0,4.05-0.39,6.09-1.2c0.49-0.2,0.98-0.44,1.44-0.7l58.28-28.02l2.21-1.11c4.62-2.87,7.39-7.81,7.39-13.22V73.29
				l-13.31-6.19L319.42,121.01z"/>
		</g>
		<g>
			<path {...st2} d="M194.88,45.97c0-0.74,0.34-1.42,0.95-1.88l59.72-28.72l0.56-0.3c0.8-0.29,1.75-0.19,2.48,0.26l42.4,20.37
				l31.65,14.74v-4.48c0-0.72-0.07-1.44-0.19-2.14c-0.04-0.4-0.11-0.81-0.2-1.23c-0.89-4.02-3.31-7.48-6.82-9.75L264.97,3.8
				c-4.18-2.35-9.05-2.84-14.07-0.84c-0.46,0.19-0.91,0.42-1.33,0.65l-58.29,28.03l-2.21,1.12c-4.63,2.87-7.39,7.8-7.39,13.21v48.01
				l13.29,6.19L194.88,45.97z"/>
		</g>
	</g>
	<g>
		<path {...st2} d="M263.94,123.97h-13.51V61.49h-23.82v-11.3h61.1v11.3h-23.77V123.97z"/>
	</g>
</g>
</svg>
)
}

