import React from 'react';
import { connect } from 'react-redux';
import './TransitionPage.scss';

function TransitionPage(props) {
    return (
        <div className='transitionPage'>
            <div className={props.transitionPageClass} />
                {props.children}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        transitionPageClass: state.ui.transitionPageClass
    }
}

export default connect(mapStateToProps)(TransitionPage)