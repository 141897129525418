import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Aux from '../../../../hoc/Auxillian';

function NavigationItem(props) {

    return (
        <Aux>
            <NavLink 
                to={props.link}
                onClick={e => props.clicked(e, props.link)}
                exact
                style={{color:props.color1}}
            >{props.children}</NavLink>
        </Aux>
    )
}

const mapStateToProps = state => {
    return {
        color1: state.ui.navFontColor
    }
}

export default connect(mapStateToProps)(NavigationItem);