import React from "react";
import NavigationItems from './NavigationItems/NavigationItems';
import SocialMedia from './SocialMedia/SocialMedia';

import './Navigation.scss';

function Navigation() {
    return (
        <nav className="navigation"><NavigationItems/><SocialMedia/></nav>
    )
}

export default Navigation;